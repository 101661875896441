import { createStore } from "vuex";
import { user } from "@/store/module/user";
import { router } from "@/store/module/router";
import { commons } from "@/store/module/common";
import { inits } from "@/store/module/init";
export default createStore({
  modules: {
    user,
    router,
    commons,
    inits,
  },
  state: {},
  getters: {},
});

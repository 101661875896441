import { getAddress } from "@/api/ssjg";

//初始化必须加载的数据
export const inits = {
  namespaced: true,
  state: {
    dis: [], //地区数据
  },
  mutations: {
    setDis(state, dis) {
      state.dis = dis;
    },
  },
  actions: {
    InitDis({ commit }) {
      getAddress({}).then((res) => {
        if (res.data.data.list) {
          let dis = res.data.data.list;
          commit("setDis", dis);
        }
      });
    },
  },
  getters: {
    dis(state) {
      return state.dis;
    },
  },
};

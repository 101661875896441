import Layout from "@/views/layout/index";

// noCache 不缓存
// hidden 不在左侧菜单栏显示
const eaRouter = [
  {
    path: "/redirects",
    component: Layout,
    name: "redirects",
    redirect: "/redirects/redirectsindex",
    hidden: true,
    meta: {
      title: "跳转页",
      noCache: true,
    },
    children: [
      {
        path: "redirectsindex",
        component: () => import("@/views/redirect/index"),
        name: "redirectsindex",
        meta: {
          title: "跳转页子叶",
          noCache: true,
        },
      },
    ],
  },
  {
    path: "/",
    name: "home",
    component: Layout,
    redirect: "/index",
    meta: {
      title: "涉税信息",
      icon: "icon-home",
    },
    children: [
      {
        path: "index",
        component: () => import("@/views/home/index.vue"),
        name: "index",
        meta: { title: "涉税信息", icon: "House" },
      },
      {
        path: "ssjg",
        component: () => import("@/views/home/ssjg.vue"),
        name: "ssjg",
        meta: { title: "首页", icon: "House" },
        hidden: true,
      },
    ],
  },
];

export default eaRouter;

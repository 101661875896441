import { loginByUsername, logout, getUserInfo } from "@/api/login";
import { getToken, setToken, removeToken } from "@/utils/auth";

import store from "../index";
import eventBus from "@/utils/bus.js"; /// mitt 总线程引入
var timer;
export const user = {
  namespaced: true,
  state: {
    token: "",
    model: "",
    user: {
      name: "",
    },
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_COMINFO: (state, comInfo) => {
      state.comInfo = comInfo;
    },
    SET_MODEL: (state, model) => {
      state.model = model;
    },
    SET_USER: (state, user) => {
      state.user = user;
    },
  },
  actions: {
    setModel({ commit }, model) {
      commit("SET_MODEL", model);
    },
    setComInfo({ commit }, comInfo) {
      commit("SET_COMINFO", comInfo);
    },
    setUser({ commit }, user) {
      commit("SET_USER", user);
    },
    // 用户名登录
    LoginByUsername({ commit }, userInfo) {
      const username = userInfo.username.trim();
      const password = userInfo.password;
      const token = userInfo.token;
      return new Promise((resolve, reject) => {
        loginByUsername(username, password, token)
          .then((response) => {
            const isDev =
              window.location.hostname == "localhost" ||
              window.location.hostname.indexOf("-dev") > -1;
            let token = "";
            if (isDev) {
              token = response.headers["token"]; // header里的属性都会转成小写才能获取
            } else {
              token = response.headers["token"]; //Cookies.get('Token') // 后端需设置httpOnly为false,否则https模式获取不到cookies
            }
            if (token) {
              commit("SET_TOKEN", token);
              setToken(token);
              resolve();
            } else {
              reject(response.data.msg);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 获取用户信息
    GetUserInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (timer) {
          window.clearInterval(timer);
        }
        timer = window.setInterval(function () {
          eventBus.emit("refreshTask");
        }, 20000);
        getUserInfo({}).then((response) => {
          const data = response.data.data;
          let user = data.user ? data.user : {};
          commit("SET_USER", user);
          //必须要初始化的数据
          store.dispatch("inits/InitDis");
          resolve(data);
        });
      });
    },
    // 登出
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        commit("SET_TOKEN", "");
        removeToken();
        resolve();
      });
    },
  },
  getters: {
    token(state) {
      return state.token;
    },
    comInfo(state) {
      return state.comInfo;
    },
    user(state) {
      return state.user;
    },
    model(state) {
      return state.model;
    },
  },
};

import { createApp } from "vue";
import ElementPlus from "element-plus";
import * as ElIcons from "@element-plus/icons-vue";
import locale from "element-plus/es/locale/lang/zh-cn";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import eventBus from '@/utils/bus.js'/// mitt 总线程引入
import "element-plus/dist/index.css";
import "./style/element.scss";

import "./assets/css/all.css";
import "./assets/icon/iconfont.css";
import "@/permission";
import component from "@/utils/component"; //注册全局组件
const app = createApp(App);
app.config.globalProperties.$bus = eventBus//挂载全局属性
import { parseTime, getHeight, goEtax, getLastDay } from "@/utils/index";
import {
  fplx,
  inventoryTypeFilter,
  inventoryCategoryFilter,
  VoucherSourceFilter,
  tableNameFilter,
  taxPayerTypeFilter,
  setUpStatusSb,
  setUpStatusGsSb,
  setUpStatusQS,
  setUpStatusQSIcon,
  setUpStatusGsKk,
  setUpStatusGsIconSb,
  setUpStatusGsWordSb,
  setUpStatusKk,
  setUpStatusJc,
  setUpStatusIconJc,
  setUpStatusCj,
  setUpStatusQY,
  setUpStatusImport,
  setUpStatusMatch,
  batchSendSb,
  batchSendIconSb,
  batchSendWordSb,
  batchSendKk,
  batchSendWordKk,
  batchSendJc,
  batchSendCj,
  batchSendIconCj,
  setUpStatusGsZf,
  setUpStatusGsIconZf,
  setUpStatusGsWordZf,
  setUpStatusJzCj,
  setUpStatusJzCjIcon,
  cityFilter,
  yearAcessStatus,
  yearAcessIconStatus,
  yearAcessWordStatus,
  batchSendYearSb,
  subclassFilter,
  codeFilter,
} from "@/utils/filter";
app.mixin({
  methods: {
    $getHeight: getHeight,

    $cityFilter: cityFilter,
    $subclassFilter: subclassFilter,
    $codeFilter: codeFilter,
  },
});
for (const iconName in ElIcons) {
  app.component(iconName, ElIcons[iconName]);
}

app
  .use(component)
  .use(ElementPlus, { locale })
  .use(store)
  .use(router)
  .mount("#app");

export function fplx(code) {
    if (code == "004") {
      return "增值税专用发票";
    } else if (code == "007") {
      return "增值税普通发票";
    } else if (code == "025") {
      return "卷式发票";
    } else if (code == "026") {
      return "电子普通发票";
    } else if (code == "005") {
      return "机动车发票";
    } else if (code == "006") {
      return "二手车销售统一发票";
    } else if (code == "028") {
      return "增值税电子专用发票";
    }
  }
  
  export function inventoryTypeFilter(type) {
    //存货类别filter
    if (type == 1) {
      return "原材料";
    } else if (type == 2) {
      return "库存商品";
    } else if (type == 3) {
      return "半成品";
    } else if (type == 4) {
      return "委托加工物资";
    } else if (type == 5) {
      return "周转材料";
    } else if (type == 6) {
      return "包装物";
    } else if (type == 7) {
      return "消耗性生物资产";
    } else if (type == 8) {
      return "工程物资";
    } else if (type == 9) {
      return "开发产品";
    } else if (type == 10) {
      return "发出产品";
    } else if (type == 11) {
      return "其他";
    }
  }
  
  export function inventoryCategoryFilter(type) {
    //存货类型filter
    if (type == 1) {
      return "初始化导入";
    } else if (type == 2) {
      return "进项入库-进项发票录入";
    } else if (type == 3) {
      return "暂估入库";
    } else if (type == 4) {
      return "生产商品入库(生产结转)";
    } else if (type == 6) {
      return "领料出库(生产结转)";
    } else if (type == 7) {
      return "商品结转出库(贸易结转)";
    } else if (type == 8) {
      return "抵消销项卖出(特殊)";
    } else if (type == 9) {
      return "销项卖出-销项发票录入";
    } else if (type == 10) {
      return "手工出库";
    } else if (type == 11) {
      return "手工入库";
    } else if (type == 12) {
      return "凭证关联出库";
    } else if (type == 13) {
      return "凭证关联入库";
    } else if (type == 31) {
      return "暂估回冲";
    } else if (type == 91) {
      return "销项回冲";
    }
  }
  
  export function VoucherSourceFilter(type) {
    //凭证来源filter
    if (type == "1") {
      return "进项";
    } else if (type == "2") {
      return "销项";
    } else if (type == "3") {
      return "费用";
    } else if (type == "4") {
      return "现金";
    } else if (type == "5") {
      return "票据";
    } else if (type == "6") {
      return "银行";
    } else if (type == "7") {
      return "工资";
    } else if (type == "8") {
      return "结转损益";
    } else if (type == "9") {
      return "计提税金";
    } else if (type == "10") {
      return "资产";
    } else if (type == "12") {
      return "存货结转";
    } else if (type == "13") {
      return "暂估回冲";
    } else if (type == "14") {
      return "暂估入库";
    } else if (type == "20") {
      return "进项认证";
    } else if (type == "30") {
      return "固定资产清理";
    } else if (type == "40") {
      return "汇兑损益";
    } else if (type == "100") {
      return "年末结转利润";
    } else if (type == "手工录入") {
      return "手工录入";
    }
  }
  
  export function tableNameFilter(type,accountSystem) {
    //税务报表名称过滤
    if (type == "kj_lrb" && accountSystem == '民办非') {
      return "业务活动表";
    } else if (type == "kj_lrb" && accountSystem == '农业合作社') {
      return "盈余及盈余分配表";
    } else if (type == "kj_zcfz") {
      return "资产负债表";
    } else if (type == "gs_vat") {
      return "增值税及附加税一般纳税人申报表";
    } else if (type == "gs_small_vat") {
      return "小规模增值税纳税及附加税申报表";
    } else if (type == "gs_deed") {
      return "财产和行为税纳税申报表";
    } else if (type == "gs_tax_quarter") {
      return "企业所得税申报表";
    } else if (type == "gs_gs") {
      return "综合所得个人所得税预扣预缴申报表";
    } else if (type == "gs_fjs") {
      return "附加税";
    } else if (type == "gs_whsyjsfsbbygz") {
      return "文化事业建设费申报表";
    } else if (type == "gs_stamp_duty_item") {
      return "印花税纳税申报表";
    } else if (type == "gs_xfs") {
      return "消费税";
    } else if (type == "gs_cbj") {
      return "残疾人就业保障金申报表";
    } else if (type == "gs_ghjf") {
      return "工会经费";
    } else if (type == "gs_fcs") {
      return "房产税土地税";
    } else if (type == "gs_fssr") {
      return "非税收入";
    } else if (type == "gs_stamp_duty_item_cb") {
      return "印花税纳税申报表";
    } else if (type == "gs_stamp_duty_item_jb") {
      return "印花税纳税申报表";
    } else if (type == "gs_sl") {
      return "水利建设专项收入";
    } else if (type == "gs_ljcl") {
      return "城市生活垃圾处置费";
    } else if (type == "gs_qtsr") {
      return "工会经费";
    } else if (type == "gs_gs_one") {
      return "个人生产经营所得(查账征收)";
    } else if (type == "kj_xjllb") {
      return "现金流量表";
    } else if (type == "gs_sb") {
      return "单位社会保险费日常申报(全责)";
    } else if (type == "gs_cwbb") {
      return "财务报表";
    }else if (type == "kj_lrb") {
      return "利润表";
    }
  }
  
  export function taxPayerTypeFilter(type) {
    if (type == "1") {
      return "小规模纳税人";
    } else if (type == "2") {
      return "一般纳税人";
    }
  }
  
  // 扣款状态
  export function setUpStatusKk(type) {
    if (type == "0" || type == "") {
      return "未扣款";
    } else if (type == "1") {
      return "排队中";
    } else if (type == "2") {
      return "扣款中";
    } else if (type == "3") {
      return "扣款成功";
    } else if (type == "4") {
      return "无需扣款";
    } else if (type == "5") {
      return "扣款异常";
    }
  }
  
  // 申报状态
  export function setUpStatusSb(type) {
    if (type == "0" || type == "") {
      return "未申报";
    } else if (type == "1") {
      return "排队中";
    } else if (type == "2") {
      return "申报中";
    } else if (type == "3") {
      return "申报完成";
    } else if (type == "4") {
      return "申报异常";
    } else if (type == "5") {
      return "税局处理中";
    } else if (type == "6") {
      return "已申报过";
    }
  }
  
  // 采集状态
  export function setUpStatusCj(type) {
    if (type == "0") {
      return "未采集";
    } else if (type == "1") {
      return "排队中";
    } else if (type == "2") {
      return "采集中";
    } else if (type == "3") {
      return "采集完成";
    } else if (type == "4") {
      return "采集失败";
    } else if (type == "5") {
      return "异常";
    }
  }
  
  // 导入状态
  export function setUpStatusImport(type) {
    if (type == "0") {
      return "未导入";
    } else if (type == "1") {
      return "排队中";
    } else if (type == "2") {
      return "导入中";
    } else if (type == "3") {
      return "导入完成";
    } else if (type == "4") {
      return "导入失败";
    } else if (type == "5") {
      return "错误";
    }
  }
  
  // 匹配状态
  export function setUpStatusMatch(type) {
    if (type == "0") {
      return "未匹配";
    } else if (type == "1") {
      return "排队中";
    } else if (type == "2") {
      return "匹配中";
    } else if (type == "3") {
      return "匹配完成";
    } else if (type == "4") {
      return "匹配失败";
    } else if (type == "5") {
      return "错误";
    }
  }
  // 采集状态
  export function setUpStatusQY(type) {
    if (type == "0") {
      return "未迁移";
    } else if (type == "1") {
      return "排队中";
    } else if (type == "2") {
      return "迁移中";
    } else if (type == "3") {
      return "迁移完成";
    } else if (type == "4") {
      return "迁移失败";
    } else if (type == "5") {
      return "异常";
    }
  }
  // 个税申报状态
  export function setUpStatusGsSb(status, type) {
    if (status == 0 || status == "") {
      return "未申报";
    } else if (status == 1) {
      return "排队中";
    } else if (status == 2) {
      return "申报中";
    } else if (status == 4) {
      return "失败";
    } else {
      if (type == "3") {
        return "申报超时失败";
      }
      if (type == "2") {
        return "申报处理中";
      } else if (type == "4") {
        return "客户端未启动失败";
      } else if (type == "5") {
        return "申报异常";
      } else if (type == "6") {
        return "申报失败";
      } else if (type == "7") {
        return "申报成功，待缴款";
      } else if (type == "8") {
        return "自动检查中";
      } else if (type == "10") {
        return "申报成功，无需缴款";
      } else if (type == "11") {
        return "扣款中";
      } else if (type == "12") {
        return "扣款异常";
      } else if (type == "13") {
        return "申报成功，扣款成功";
      } else if (type == "14") {
        return "已申报过";
      } else if (type == "20") {
        return "申报成功";
      } else {
        return "未知状态";
      }
    }
  }
  
  export function setUpStatusGsKk(status, type) {
    if (status == 0 || status == "") {
      return "未开始";
    } else if (status == 1) {
      return "排队中";
    } else if (status == 2) {
      return "扣款中";
    } else if (status == 4) {
      return "失败";
    } else {
      if (type == "3") {
        return "申报超时失败";
      }
      if (type == "2") {
        return "申报处理中";
      } else if (type == "4") {
        return "客户端未启动失败";
      } else if (type == "5") {
        return "申报异常";
      } else if (type == "6") {
        return "申报失败";
      } else if (type == "7") {
        return "申报成功，待缴款";
      } else if (type == "8") {
        return "自动检查中";
      } else if (type == "10") {
        return "申报成功，无需缴款";
      } else if (type == "11") {
        return "扣款中";
      } else if (type == "12") {
        return "扣款异常";
      } else if (type == "13") {
        return "申报成功，扣款成功";
      } else if (type == "14") {
        return "已申报过";
      } else if (type == "20") {
        return "申报成功";
      } else {
        return "未知状态";
      }
    }
  }
  
  export function setUpStatusGsIconSb(status, type) {
    if (status == 0 || status == "") {
      return "iconfont icon-gantanhao";
    } else if (status == 1) {
      return "iconfont icon-info";
    } else if (status == 2) {
      return "iconfont icon-info";
    } else if (status == 4) {
      return "iconfont icon-cuowu";
    } else {
      if (type == "3") {
        return "iconfont icon-cuowu";
      } else if (type == "2") {
        return "iconfont icon-info";
      } else if (type == "4") {
        return "iconfont icon-cuowu";
      } else if (type == "5") {
        return "iconfont icon-cuowu";
      } else if (type == "6") {
        return "iconfont icon-cuowu";
      } else if (type == "7") {
        return "iconfont icon-info";
      } else if (type == "8") {
        return "iconfont icon-info";
      } else if (type == "10") {
        return "iconfont icon-duihao";
      } else if (type == "11") {
        return "iconfont icon-info";
      } else if (type == "12") {
        return "iconfont icon-cuowu";
      } else if (type == "13") {
        return "iconfont icon-duihao";
      } else if (type == "14") {
        return "iconfont icon-duihao";
      }
    }
  }
  
  export function setUpStatusGsWordSb(status, type) {
    if (status == 0 || status == "") {
      return "wsb";
    } else if (status == 1) {
      return "sbz";
    } else if (status == 2) {
      return "sbz";
    } else if (status == 4) {
      return "sbyc2";
    } else {
      if (type == "3") {
        return "sbyc2";
      } else if (type == "2") {
        return "sbz";
      } else if (type == "4") {
        return "sbyc2";
      } else if (type == "5") {
        return "sbyc2";
      } else if (type == "6") {
        return "sbyc2";
      } else if (type == "7") {
        return "sbz";
      } else if (type == "8") {
        return "sbz";
      } else if (type == "10") {
        return "sbcg2";
      } else if (type == "11") {
        return "sbz";
      } else if (type == "12") {
        return "sbyc2";
      } else if (type == "13") {
        return "sbcg2";
      } else if (type == "14") {
        return "sbcg2";
      }
    }
  }
  
  // 税种申报 申报任务结果状态
  export function batchSendSb(status, type) {
    if (status == 0 || status == "") {
      return "未申报";
    } else if (status == 1) {
      return "排队中";
    } else if (status == 2) {
      return "申报中";
    } else if (status == 4) {
      return "失败";
    } else {
      if (type == "2") {
        return "申报成功无需扣款";
      } else if (type == "3") {
        return "申报失败";
      } else if (type == "4") {
        return "提交金三处理中";
      } else if (type == "5") {
        return "申报成功,待缴款";
      } else if (type == "6") {
        return "申报成功,已缴款";
      } else if (type == "20") {
        return "已申报过，无需扣款";
      } else if (type == "21") {
        return "已申报过，待缴款";
      } else if (type == "22") {
        return "已申报过，已缴款";
      } else {
        return "未知状态";
      }
    }
  }
  
  export function batchSendIconSb(status, type) {
    if (status == 0 || status == "") {
      return "iconfont icon-gantanhao";
    } else if (status == 1) {
      return "iconfont icon-info";
    } else if (status == 2) {
      return "iconfont icon-info";
    } else if (status == 4) {
      return "iconfont icon-cuowu";
    } else {
      if (type == "2") {
        return "iconfont icon-duihao";
      } else if (type == "3") {
        return "iconfont icon-cuowu";
      } else if (type == "4") {
        return "iconfont icon-info";
      } else if (type == "5") {
        return "iconfont icon-duihao";
      } else if (type == "6") {
        return "iconfont icon-duihao";
      } else if (type == "20") {
        return "iconfont icon-duihao";
      } else if (type == "13") {
        return "iconfont icon-duihao";
      } else if (type == "21") {
        return "iconfont icon-duihao";
      } else if (type == "22") {
        return "iconfont icon-duihao";
      } else {
        return "未知状态";
      }
    }
  }
  
  export function batchSendWordSb(status, type) {
    if (status == 0 || status == "") {
      return "wsb";
    } else if (status == 1) {
      return "sbz";
    } else if (status == 2) {
      return "sbz";
    } else if (status == 4) {
      return "sbyc2";
    } else {
      if (type == "2") {
        return "sbcg2";
      } else if (type == "3") {
        return "sbyc2";
      } else if (type == "4") {
        return "sbz";
      } else if (type == "5") {
        return "sbz";
      } else if (type == "6") {
        return "sbcg2";
      } else if (type == "20") {
        return "sbcg2";
      } else if (type == "21") {
        return "sbz";
      } else if (type == "22") {
        return "sbcg2";
      } else {
        return "未知状态";
      }
    }
  }
  
  // 税种缴款 扣款任务结果状态
  export function batchSendKk(status, type) {
    if (status == 0 || status == "") {
      return "未扣款";
    } else if (status == 1) {
      return "排队中";
    } else if (status == 2) {
      return "扣款中";
    } else if (status == 4) {
      return "失败";
    } else {
      if (type == "2") {
        return "成功";
      } else if (type == "3") {
        return "失败";
      }else if (type == "13") {
        return "缴款成功";
      } else {
        return "未知状态";
      }
    }
  }
  
  // 税种缴款 扣款任务结果状态 字体颜色
  export function batchSendWordKk(status, type) {
    if (status == 0 || status == "") {
      return "wsb";
    } else if (status == 1) {
      return "sbz";
    } else if (status == 2) {
      return "sbz";
    } else if (status == 4) {
      return "sbyc2";
    } else {
      if (type == "2") {
        return "sbcg2";
      } else if (type == "3") {
        return "sbyc2";
      } else {
        return "未知状态";
      }
    }
  }
  
  // 申报检查任务结果状态
  export function batchSendJc(status, type) {
    if (status == 0 || status == "") {
      return "未检查";
    } else if (status == 1) {
      return "排队中";
    } else if (status == 2) {
      return "检查中";
    } else if (status == 4) {
      return "失败";
    } else {
      if (type == "2") {
        return "成功";
      } else if (type == "3") {
        return "失败";
      } else {
        return "未知状态";
      }
    }
  }
  
  // 采集发票
  export function batchSendCj(status, type) {
    if (status == 0 || status == "") {
      return "未采集";
    } else if (status == 1) {
      return "排队中";
    } else if (status == 2) {
      return "采集中";
    } else if (status == 4) {
      return "失败";
    } else if (status == 8) {
      return "任务已撤销";
    } else {
      if (type == "2") {
        return "成功";
      } else if (type == "3") {
        return "失败";
      } else if (type == "4") {
        return "税局审核中";
      } else if (type == "5") {
        return "成功（未勾选发票）";
      } else {
        return "未知状态";
      }
    }
  }
  
  export function batchSendIconCj(status, type) {
    if (status == 0 || status == "") {
      return "iconfont icon-gantanhao";
    } else if (status == 1) {
      return "iconfont icon-info";
    } else if (status == 2) {
      return "iconfont icon-info";
    } else if (status == 4) {
      return "iconfont icon-cuowu";
    } else {
      if (type == "2") {
        return "iconfont icon-duihao";
      } else if (type == "3") {
        return "iconfont icon-cuowu";
      } else if (type == "4") {
        return "iconfont icon-info";
      } else if (type == "5") {
        return "iconfont icon-duihao";
      }
    }
  }
  
  // 检查状态
  export function setUpStatusJc(status, type) {
    if (type == "task") {
      if (status == 0 || status == "") {
        return "未检查";
      } else if (status == 1) {
        return "排队中";
      } else if (status == 2) {
        return "检查中";
      } else if (status == 3) {
        return "检查完成";
      } else if (status == 4) {
        return "检查失败";
      }
    } else {
      if (status == "2") {
        return "检查通过";
      } else if (status == "3") {
        return "检查失败";
      }
    }
  }
  
  export function setUpStatusIconJc(status, type) {
    if (type == "task") {
      if (status == 0 || status == "") {
        return "iconfont icon-gantanhao";
      } else if (status == 1) {
        return "iconfont icon-info";
      } else if (status == 2) {
        return "iconfont icon-info";
      } else if (status == 3) {
        return "iconfont icon-duihao";
      } else if (status == 4) {
        return "iconfont icon-cuowu";
      }
    } else {
      if (status == "2") {
        return "iconfont icon-duihao";
      } else if (status == "3") {
        return "iconfont icon-cuowu";
      }
    }
  }
  
  // 个税作废状态
  export function setUpStatusGsZf(status, type) {
    if (status == 0 || status == "") {
      return "未作废";
    } else if (status == 1) {
      return "排队中";
    } else if (status == 2) {
      return "申报中";
    } else if (status == 4) {
      return "失败";
    } else {
      if (type == "2") {
        return "成功";
      } else if (type == "3") {
        return "失败";
      }
    }
  }
  
  export function setUpStatusGsIconZf(status, type) {
    if (status == 0 || status == "") {
      return "iconfont icon-gantanhao";
    } else if (status == 1) {
      return "iconfont icon-info";
    } else if (status == 2) {
      return "iconfont icon-info";
    } else if (status == 4) {
      return "iconfont icon-cuowu";
    } else {
      if (type == "2") {
        return "iconfont icon-duihao";
      } else if (type == "3") {
        return "iconfont icon-cuowu";
      }
    }
  }
  
  export function setUpStatusGsWordZf(status, type) {
    if (status == 0 || status == "") {
      return "wsb";
    } else if (status == 1) {
      return "sbz";
    } else if (status == 2) {
      return "sbz";
    } else if (status == 4) {
      return "sbyc2";
    } else {
      if (type == "2") {
        return "sbcg2";
      } else if (type == "3") {
        return "sbyc2";
      }
    }
  }
  
  // 采集科目余额表状态
  export function setUpStatusJzCj(status, type) {
    if (status == 0 || status == "") {
      return "未采集";
    } else if (status == 1) {
      return "排队中";
    } else if (status == 2) {
      return "采集中";
    } else if (status == 4) {
      return "失败";
    } else {
      if (type == "2") {
        return "采集成功";
      } else if (type == "3") {
        return "采集失败";
      }
    }
  }
  
  
  export function setUpStatusQS(type){
    if(type == '0'){
      return '未取数'
    }else if(type == '1'){
      return '排队中'
    }else if(type == '2'){
      return '取数中'
    }else if(type == '3'){
      return '取数完成'
    }else if(type == '4'){
      return '取数失败'
    }else if(type == '5'){
      return '错误'
    }
  }
  
  export function setUpStatusQSIcon(type){
    if(type == '0'){
      return "iconfont icon-gantanhao"
    }else if(type == '1'){
      return "iconfont icon-info"
    }else if(type == '2'){
      return "iconfont icon-info"
    }else if(type == '3'){
      return 'iconfont icon-duihao'
    }else if(type == '4'){
      return 'iconfont icon-cuowu'
    }else if(type == '5'){
      return 'iconfont icon-cuowu'
    }
  }
  
  export function setUpStatusJzCjIcon(status, type) {
    if (status == 0 || status == "") {
      return "iconfont icon-gantanhao";
    } else if (status == 1) {
      return "iconfont icon-info";
    } else if (status == 2) {
      return "iconfont icon-info";
    } else if (status == 4) {
      return "iconfont icon-cuowu";
    } else {
      if (type == "2") {
        return "iconfont icon-duihao";
      } else if (type == "3") {
        return "iconfont icon-cuowu";
      }
    }
  }
  
  // 城市筛选
  export function cityFilter(type) {
    if (type == "beijing") {
      return "北京";
    } else if (type == "shandong") {
      return "山东";
    } else if (type == "qingdao") {
      return "青岛";
    } else if (type == "neimenggu") {
      return "内蒙古";
    } else if (type == "shanxi") {
      return "山西";
    } else if (type == "tianjin") {
      return "天津";
    } else if (type == "sichuan") {
      return "四川";
    } else if (type == "jiangsu") {
      return "江苏";
    } else if (type == "hebei") {
      return "河北";
    } else if (type == "xizang") {
      return "西藏";
    } else if (type == "qinghai") {
      return "青海";
    } else if (type == "henan") {
      return "河南";
    } else if (type == "shaanxi") {
      return "陕西";
    } else if (type == "hainan") {
      return "海南";
    } else if (type == "liaoning") {
      return "辽宁";
    } else if (type == "shanghai") {
      return "上海";
    } else if (type == "hunan") {
      return "湖南";
    } else if (type == "guangdong") {
      return "广东";
    } else if (type == "shenzhen") {
      return "深圳";
    } else if (type == "guangxi") {
      return "广西";
    } else if (type == "chongqing") {
      return "重庆";
    } else if (type == "yunnan") {
      return "云南";
    } else if (type == "xinjiang") {
      return "新疆";
    } else if (type == "anhui") {
      return "安徽";
    } else if (type == "hubei") {
      return "湖北";
    } else if (type == "guizhou") {
      return "贵州";
    } else if (type == "dalian") {
      return "大连";
    } else if (type == "ningbo") {
      return "宁波";
    } else if (type == "xiamen") {
      return "厦门";
    } else if (type == "ningxia") {
      return "宁夏";
    } else if (type == "jiangxi") {
      return "江西";
    } else if (type == "jilin") {
      return "吉林";
    } else if (type == "heilongjiang") {
      return "黑龙江";
    } else if (type == "zhejiang") {
      return "浙江";
    } else if (type == "fujian") {
      return "福建";
    } else if (type == "guizhou") {
      return "贵州";
    } else if (type == "gansu") {
      return "甘肃";
    }
  }
  //地区code筛选
  export function codeFilter(type) {
    if (type == "110000") {
      return "beijing";
    } else if (type == "370000") {
      return "shandong";
    } else if (type == "370200") {
      return "qingdao";
    } else if (type == "150000") {
      return "neimenggu";
    } else if (type == "140000") {
      return "shanxi";
    } else if (type == "120000") {
      return "tianjin";
    } else if (type == "510000") {
      return "sichuan";
    } else if (type == "320000") {
      return "jiangsu";
    } else if (type == "130000") {
      return "hebei";
    } else if (type == "540000") {
      return "xizang";
    } else if (type == "630000") {
      return "qinghai";
    } else if (type == "410000") {
      return "henan";
    } else if (type == "610000") {
      return "shaanxi";
    } else if (type == "460000") {
      return "hainan";
    } else if (type == "210000") {
      return "liaoning";
    } else if (type == "310000") {
      return "shanghai";
    } else if (type == "430000") {
      return "hunan";
    } else if (type == "440000") {
      return "guangdong";
    } else if (type == "440300") {
      return "shenzhen";
    } else if (type == "450000") {
      return "guangxi";
    } else if (type == "500000") {
      return "chongqing";
    } else if (type == "530000") {
      return "yunnan";
    } else if (type == "650000") {
      return "xinjiang";
    } else if (type == "340000") {
      return "anhui";
    } else if (type == "420000") {
      return "hubei";
    } else if (type == "520000") {
      return "guizhou";
    } else if (type == "210200") {
      return "dalian";
    } else if (type == "330200") {
      return "ningbo";
    } else if (type == "350200") {
      return "xiamen";
    } else if (type == "640000") {
      return "ningxia";
    } else if (type == "360000") {
      return "jiangxi";
    } else if (type == "220000") {
      return "jilin";
    } else if (type == "230000") {
      return "heilongjiang";
    } else if (type == "330000") {
      return "zhejiang";
    } else if (type == "350000") {
      return "fujian";
    } else if (type == "620000") {
      return "gansu";
    } else if (type == "beijing") {
      return "110000";
    } else if (type == "shandong") {
      return "370000";
    } else if (type == "qingdao") {
      return "370200";
    } else if (type == "neimenggu") {
      return "150000";
    } else if (type == "shanxi") {
      return "140000";
    } else if (type == "tianjin") {
      return "120000";
    } else if (type == "sichuan") {
      return "510000";
    } else if (type == "jiangsu") {
      return "320000";
    } else if (type == "hebei") {
      return "130000";
    } else if (type == "xizang") {
      return "540000";
    } else if (type == "qinghai") {
      return "630000";
    } else if (type == "henan") {
      return "410000";
    } else if (type == "shaanxi") {
      return "610000";
    } else if (type == "hainan") {
      return "460000";
    } else if (type == "liaoning") {
      return "210000";
    } else if (type == "shanghai") {
      return "310000";
    } else if (type == "hunan") {
      return "430000";
    } else if (type == "guangdong") {
      return "440000";
    } else if (type == "shenzhen") {
      return "440300";
    } else if (type == "guangxi") {
      return "450000";
    } else if (type == "chongqing") {
      return "500000";
    } else if (type == "yunnan") {
      return "530000";
    } else if (type == "xinjiang") {
      return "650000";
    } else if (type == "anhui") {
      return "340000";
    } else if (type == "hubei") {
      return "420000";
    } else if (type == "guizhou") {
      return "520000";
    } else if (type == "dalian") {
      return "210200";
    } else if (type == "ningbo") {
      return "330200";
    } else if (type == "xiamen") {
      return "350200";
    } else if (type == "ningxia") {
      return "640000";
    } else if (type == "jiangxi") {
      return "360000";
    } else if (type == "jilin") {
      return "220000";
    } else if (type == "heilongjiang") {
      return "230000";
    } else if (type == "zhejiang") {
      return "330000";
    } else if (type == "fujian") {
      return "350000";
    } else if (type == "gansu") {
      return "620000";
    } else if (type == "北京") {
      return "beijing";
    } else if (type == "山东") {
      return "shandong";
    } else if (type == "青岛") {
      return "qingdao";
    } else if (type == "内蒙古") {
      return "neimenggu";
    } else if (type == "山西") {
      return "shanxi";
    } else if (type == "天津") {
      return "tianjin";
    } else if (type == "四川") {
      return "sichuan";
    } else if (type == "江苏") {
      return "jiangsu";
    } else if (type == "河北") {
      return "hebei";
    } else if (type == "西藏") {
      return "xizang";
    } else if (type == "青海") {
      return "qinghai";
    } else if (type == "河南") {
      return "henan";
    } else if (type == "陕西") {
      return "shaanxi";
    } else if (type == "海南") {
      return "hainan";
    } else if (type == "辽宁") {
      return "liaoning";
    } else if (type == "上海") {
      return "shanghai";
    } else if (type == "湖南") {
      return "hunan";
    } else if (type == "广东") {
      return "guangdong";
    } else if (type == "深圳") {
      return "shenzhen";
    } else if (type == "广西") {
      return "guangxi";
    } else if (type == "重庆") {
      return "chongqing";
    } else if (type == "云南") {
      return "yunnan";
    } else if (type == "新疆") {
      return "xinjiang";
    } else if (type == "安徽") {
      return "anhui";
    } else if (type == "湖北") {
      return "hubei";
    } else if (type == "贵州") {
      return "guizhou";
    } else if (type == "大连") {
      return "dalian";
    } else if (type == "宁波") {
      return "ningbo";
    } else if (type == "厦门") {
      return "xiamen";
    } else if (type == "宁夏") {
      return "ningxia";
    } else if (type == "江西") {
      return "jiangxi";
    } else if (type == "吉林") {
      return "jilin";
    } else if (type == "黑龙江") {
      return "heilongjiang";
    } else if (type == "浙江") {
      return "zhejiang";
    } else if (type == "福建") {
      return "fujian";
    } else if (type == "贵州") {
      return "guizhou";
    } else if (type == "甘肃") {
      return "gansu";
    }
  }
  
  // 汇算清缴取数状态
  export function yearAcessStatus(type) {
    if (type == 0) {
      return "未取数";
    } else if (type == 1) {
      return "取数中";
    } else if (type == 3) {
      return "取数成功";
    } else if (type == 4) {
      return "取数失败";
    }
  }
  
  export function yearAcessIconStatus(type) {
    if (type == 0) {
      return "iconfont icon-gantanhao";
    } else if (type == 1) {
      return "iconfont icon-info";
    } else if (type == 3) {
      return "iconfont icon-duihao";
    } else if (type == 4) {
      return "iconfont icon-cuowu";
    }
  }
  
  export function yearAcessWordStatus(type) {
    if (type == 0) {
      return "wsb";
    } else if (type == 1) {
      return "sbz";
    } else if (type == 3) {
      return "sbcg2";
    } else if (type == 4) {
      return "sbyc2";
    }
  }
  
  // 税种申报 申报任务结果状态
  export function batchSendYearSb(status, type) {
    if (status == 0 || status == "") {
      return "未申报";
    } else if (status == 1) {
      return "排队中";
    } else if (status == 2) {
      return "申报中";
    } else if (status == 4) {
      return "失败";
    } else {
      if (type == "2") {
        return "申报成功,无需扣款";
      } else if (type == "3") {
        return "申报失败";
      } else if (type == "4") {
        return "提交金三处理中";
      } else if (type == "5") {
        return "申报成功,待缴款";
      } else if (type == "6") {
        return "申报成功,已缴款";
      } else if (type == "20") {
        return "已申报过，无需扣款";
      } else if (type == "21") {
        return "己申报过，待缴款";
      } else if (type == "22") {
        return "已申报过，已缴款";
      } else {
        return "未知状态";
      }
    }
  }
  
  //涉税委托服务项目小类
  export function subclassFilter(item) {
    if (item.wtfwxmdl == "纳税申报代理" && item.wtfwxmzl == "申报") {
      return [
        {
          label: "增值税申报",
          value: "增值税申报",
        },
        {
          label: "定期定额申报",
          value: "定期定额申报",
        },
        {
          label: "车辆购置税申报",
          value: "车辆购置税申报",
        },
        {
          label: "委托代征、代售申报",
          value: "委托代征、代售申报",
        },
        {
          label: "代扣代缴、代收代缴申报",
          value: "代扣代缴、代收代缴申报",
        },
        {
          label: "财务报表报送与信息采集",
          value: "财务报表报送与信息采集",
        },
        {
          label: "资料报送与信息采集",
          value: "资料报送与信息采集",
        },
        {
          label: "申报错误更正",
          value: "申报错误更正",
        },
        {
          label: "延期申报核准",
          value: "延期申报核准",
        },
        {
          label: "欠税人处置不动产或者大额资产报告",
          value: "欠税人处置不动产或者大额资产报告",
        },
        {
          label: "非居民合同项目备案",
          value: "非居民合同项目备案",
        },
        {
          label: "消费税申报",
          value: "消费税申报",
        },
        {
          label: "企业所得税申报",
          value: "企业所得税申报",
        },
        {
          label: "个人所得税申报",
          value: "个人所得税申报",
        },
        {
          label: "财产行为税申报",
          value: "财产行为税申报",
        },
        {
          label: "附加税 (费) 申报",
          value: "附加税 (费) 申报",
        },
        {
          label: "基金规费申报",
          value: "基金规费申报",
        },
        {
          label: "通用申报 (税及附征税费)",
          value: "通用申报 (税及附征税费)",
        },
        {
          label: "土地增值税清算申报",
          value: "土地增值税清算申报",
        },
        {
          label: "企业所得税汇总纳税总分机构信息备案",
          value: "企业所得税汇总纳税总分机构信息备案",
        },
        {
          label: "增值税进项税额扣除标准核定(依申请)",
          value: "增值税进项税额扣除标准核定(依申请)",
        },
        {
          label: "增值税进项税额扣除标准备案",
          value: "增值税进项税额扣除标准备案",
        },
        {
          label: "非居民企业间接转让财产事项报告",
          value: "非居民企业间接转让财产事项报告",
        },
        {
          label: "个人所得税分期缴纳备案",
          value: "个人所得税分期缴纳备案",
        },
        {
          label: "个人所得税递延纳税",
          value: "个人所得税递延纳税",
        },
      ];
    } else if (item.wtfwxmdl == "涉税鉴证") {
      return [
        {
          label: "企业所得税汇算清缴纳税申报鉴证业务",
          value: "企业所得税汇算清缴纳税申报鉴证业务",
        },
        {
          label: "企业税前弥补亏损鉴证业务",
          value: "企业税前弥补亏损鉴证业务",
        },
        {
          label: "企业资产损失税前扣除鉴证业务",
          value: "企业资产损失税前扣除鉴证业务",
        },
        {
          label: "土地增值税清算鉴证业务",
          value: "土地增值税清算鉴证业务",
        },
        {
          label: "高新技术企业认定鉴证业务",
          value: "高新技术企业认定鉴证业务",
        },
        {
          label: "企业注销税务登记税款清算鉴证业务",
          value: "企业注销税务登记税款清算鉴证业务",
        },
        {
          label: "研发费加计扣除鉴证业务",
          value: "研发费加计扣除鉴证业务",
        },
        {
          label: "其他涉税鉴证",
          value: "其他涉税鉴证",
        },
      ];
    } else if (item.wtfwxmdl == "其他税务事项代理" && item.wtfwxmzl == "登记") {
      return [
        {
          label: "纳税人(扣缴义务人)身份信息报告",
          value: "纳税人(扣缴义务人)身份信息报告",
        },
        {
          label: "停业登记",
          value: "停业登记",
        },
        {
          label: "复业登记",
          value: "复业登记",
        },
        {
          label: "注销税务登记",
          value: "注销税务登记",
        },
        {
          label: "文化事业建设费缴费信息登记",
          value: "文化事业建设费缴费信息登记",
        },
        {
          label: "汽油、柴油生产企业基本情况登记",
          value: "汽油、柴油生产企业基本情况登记",
        },
        {
          label: "注销扣缴税款登记",
          value: "注销扣缴税款登记",
        },
        {
          label: "自然人信息登记",
          value: "自然人信息登记",
        },
        {
          label: "自然人信息变更",
          value: "自然人信息变更",
        },
        {
          label: "税源管理项目登记",
          value: "税源管理项目登记",
        },
        {
          label: "跨区域涉税事项报告",
          value: "跨区域涉税事项报告",
        },
        {
          label: "税源管理项目注销登记",
          value: "税源管理项目注销登记",
        },
        {
          label: "跨区域涉税事项反馈信息处理",
          value: "跨区域涉税事项反馈信息处理",
        },
        {
          label: "取消非正常户认定",
          value: "取消非正常户认定",
        },
        {
          label: "一照一码户信息变更",
          value: "一照一码户信息变更",
        },
        {
          label: "跨区域涉税事项报验",
          value: "跨区域涉税事项报验",
        },
        {
          label: "跨区域涉税事项信息反馈",
          value: "跨区域涉税事项信息反馈",
        },
        {
          label: "证件增补发",
          value: "证件增补发",
        },
        {
          label: "存款账户账号报告",
          value: "存款账户账号报告",
        },
        {
          label: "财务会计制度备案",
          value: "财务会计制度备案",
        },
        {
          label: "合并分立报告",
          value: "合并分立报告",
        },
        {
          label: "一照一码户清税申报",
          value: "一照一码户清税申报",
        },
        {
          label: "一般纳税人登记",
          value: "一般纳税人登记",
        },
        {
          label: "财产行为税税源信息报告",
          value: "财产行为税税源信息报告",
        },
        {
          label: "水资源税税源登记",
          value: "水资源税税源登记",
        },
        {
          label: "企业所得税清算报备",
          value: "企业所得税清算报备",
        },
        {
          label: "两证整合个体工商户清税申报",
          value: "两证整合个体工商户清税申报",
        },
        {
          label: "货物运输业小规模纳税人异地代开增值税专用发票备案",
          value: "货物运输业小规模纳税人异地代开增值税专用发票备案",
        },
      ];
    } else if (item.wtfwxmdl == "其他税务事项代理" && item.wtfwxmzl == "发票") {
      return [
        {
          label: "开具红字发票信息表",
          value: "开具红字发票信息表",
        },
        {
          label: "作废开具红字发票信息表",
          value: "作废开具红字发票信息表",
        },
        {
          label: "增值税抵扣凭证抵扣申请",
          value: "增值税抵扣凭证抵扣申请",
        },
        {
          label: "发票票种核定",
          value: "发票票种核定",
        },
        {
          label: "发票领用",
          value: "发票领用",
        },
        {
          label: "发票用票特批",
          value: "发票用票特批",
        },
        {
          label: "发票退票",
          value: "发票退票",
        },
        {
          label: "发票代开",
          value: "发票代开",
        },
        {
          label: "代开发票作废",
          value: "代开发票作废",
        },
        {
          label: "发票验（交）旧",
          value: "发票验（交）旧",
        },
        {
          label: "发票遗失、损毁报告",
          value: "发票遗失、损毁报告",
        },
        {
          label: "发票缴销",
          value: "发票缴销",
        },
        {
          label: "有奖发票兑奖",
          value: "有奖发票兑奖",
        },
        {
          label: "存根联数据采集",
          value: "存根联数据采集",
        },
        {
          label: "抵扣联数据采集",
          value: "抵扣联数据采集",
        },
        {
          label: "发票防伪用品领购",
          value: "发票防伪用品领购",
        },
        {
          label: "发票防伪用品核销",
          value: "发票防伪用品核销",
        },
        {
          label: "印制有本单位名称发票",
          value: "印制有本单位名称发票",
        },
        {
          label: "代开发票信息采集",
          value: "代开发票信息采集",
        },
      ];
    } else if (item.wtfwxmdl == "其他税务事项代理" && item.wtfwxmzl == "优惠") {
      return [
        {
          label: "税收优惠资格取消",
          value: "税收优惠资格取消",
        },
        {
          label: "增值税即征即退备案",
          value: "增值税即征即退备案",
        },
        {
          label: "税收减免核准",
          value: "税收减免核准",
        },
        {
          label: "税收减免备案",
          value: "税收减免备案",
        },
        {
          label: "税前扣除备案",
          value: "税前扣除备案",
        },
        {
          label: "购置专用设备投资抵免企业所得税",
          value: "购置专用设备投资抵免企业所得税",
        },
        {
          label: "跨境应税行为免征增值税报告",
          value: "跨境应税行为免征增值税报告",
        },
        {
          label: "纳税人放弃免税权声明",
          value: "纳税人放弃免税权声明",
        },
        {
          label: "非居民纳税人享受税收协定待遇",
          value: "非居民纳税人享受税收协定待遇",
        },
      ];
    } else if (item.wtfwxmdl == "其他税务事项代理" && item.wtfwxmzl == "认定") {
      return [
        {
          label: "非居民企业股权转让适用特殊性税务处理的备案",
          value: "非居民企业股权转让适用特殊性税务处理的备案",
        },
        {
          label: "增值税、消费税汇总纳税认定",
          value: "增值税、消费税汇总纳税认定",
        },
        {
          label: "境外注册中资控股企业居民身份认定（依申请）",
          value: "境外注册中资控股企业居民身份认定（依申请）",
        },
      ];
    } else if (item.wtfwxmdl == "其他税务事项代理" && item.wtfwxmzl == "证明") {
      return [
        {
          label: "非居民企业汇总申报企业所得税证明",
          value: "非居民企业汇总申报企业所得税证明",
        },
        {
          label: "开具税收完税（费）证明'",
          value: "开具税收完税（费）证明'",
        },
        {
          label: "中国税收居民身份证明",
          value: "中国税收居民身份证明",
        },
        {
          label: "服务贸易等项目对外支付税务备案",
          value: "服务贸易等项目对外支付税务备案",
        },
      ];
    } else if (item.wtfwxmdl == "其他税务事项代理" && item.wtfwxmzl == "出口退税") {
      return [
        {
          label: "出口企业放弃免税权备案",
          value: "出口企业放弃免税权备案",
        },
        {
          label: "出口企业放弃退（免）税权备案'",
          value: "出口企业放弃退（免）税权备案'",
        },
        {
          label: "出口退税备案管理",
          value: "出口退税备案管理",
        },
        {
          label: "其他出口退税备案管理",
          value: "其他出口退税备案管理",
        },
        {
          label: "退税商店备案",
          value: "退税商店备案",
        },
        {
          label: "退税商店备案变更",
          value: "退税商店备案变更",
        },
        {
          label: "出口退（免）税证明开具",
          value: "出口退（免）税证明开具",
        },
        {
          label: "来料加工免税证明及核销办理",
          value: "来料加工免税证明及核销办理",
        },
        {
          label: "补办出口退（免）税证明",
          value: "补办出口退（免）税证明",
        },
        {
          label: "出口退（免）税延期申报核准",
          value: "出口退（免）税延期申报核准",
        },
        {
          label: "出口退税资料报送与信息采集",
          value: "出口退税资料报送与信息采集",
        },
        {
          label: "出口货物免退税申报",
          value: "出口货物免退税申报",
        },
        {
          label: "生产企业消费税特准退税核准",
          value: "生产企业消费税特准退税核准",
        },
        {
          label: "出口货物先退税后核销的核销",
          value: "出口货物先退税后核销的核销",
        },
        {
          label: "出口货物免抵退税申报",
          value: "出口货物免抵退税申报",
        },
        {
          label: "进料加工企业计划分配率备案",
          value: "进料加工企业计划分配率备案",
        },
        {
          label: "生产企业进料加工业务免抵退税核销",
          value: "生产企业进料加工业务免抵退税核销",
        },
        {
          label: "退税代理机构结算核准",
          value: "退税代理机构结算核准",
        },
        {
          label: "出口卷烟相关证明及免税核销办理",
          value: "出口卷烟相关证明及免税核销办理",
        },
        {
          label: "进料加工企业计划分配率备案调整核准",
          value: "进料加工企业计划分配率备案调整核准",
        },
        {
          label: "边贸代理出口备案",
          value: "边贸代理出口备案",
        },
        {
          label: "作废出口退（免）税证明",
          value: "作废出口退（免）税证明",
        },
      ];
    } else if (item.wtfwxmdl == "其他税务事项代理" && item.wtfwxmzl == "评估审计") {
      return [
        {
          label: "预约定价安排程序执行情况 (依申请)",
          value: "预约定价安排程序执行情况 (依申请)",
        },
      ];
    } else if (item.wtfwxmdl == "其他税务事项代理" && item.wtfwxmzl == "法制") {
      return [
        {
          label: "纳税担保申请确认",
          value: "纳税担保申请确认",
        },
        {
          label: "税务行政复议申请处理",
          value: "税务行政复议申请处理",
        },
        {
          label: "复议申请撤回",
          value: "复议申请撤回",
        },
        {
          label: "复议听证（依申请）",
          value: "复议听证（依申请）",
        },
        {
          label: "停止具体行政行为处理（依申请）",
          value: "停止具体行政行为处理（依申请）",
        },
      ];
    } else if (item.wtfwxmdl == "其他税务事项代理" && item.wtfwxmzl == "综合") {
      return [
        {
          label: "税务行政许可",
          value: "税务行政许可",
        },
        {
          label: "变更税务行政许可",
          value: "变更税务行政许可",
        },
        {
          label: "纳税信用补评和复评",
          value: "纳税信用补评和复评",
        },
      ];
    } else {
      return [];
    }
  }
  
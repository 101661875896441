<template>
  <div class="big">
    <div class="bottom">
      <div class="right">
        <div class="top">
          <headers></headers>
        </div>
        <div class="left">
          <left></left>
        </div>
        <div class="rightStyle">
          <right></right>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import headers from "./components/header.vue";
import left from "./components/left.vue";
import right from "./components/right.vue";

export default {
  name: "layout",
  components: {
    left,
    right,
    headers,
  },
  setup() {},
  data() {
    return {};
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.big {
  height: 100%;
  .top {
    width: 100%;
    height: 70px;
    background: #ccc;
  }
  .bottom {
    width: 100%;
    height: 100%;
    .left {
      float: left;
      width: 170px;
      height: calc(100% - 70px);
      overflow-x: hidden;
    }
    .left::-webkit-scrollbar {
      width: 4px;
    }
    /*滚动条里面小方块*/
    .left::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: rgba(100, 100, 100, 0.2);
    }
    .right {
      float: left;
      width: calc(100% - 1px);
      // 变量
      height: 98%;
      background: #efefef;
      .rightStyle {
        float: right;
        width: calc(100% - 170px);
      }
    }
  }
}
</style>

import axios from "axios";
import { ElMessage } from "element-plus";
import store from "@/store";
import { getToken, removeToken } from "@/utils/auth";

const service = axios.create({
  baseURL: process.env.BASE_API, // api 的 base_url
  timeout: 600000, // request timeout
});
let loadingInstance;
service.interceptors.request.use(
  (config) => {
    if (getToken()) {
      config.headers["Token"] = getToken();
    }
    return config;
  },
  (response) => {
    console.log("返回23：" + response);
  },
  (error) => {
    console.log(error); // for debug
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    if (response.data) {
      if (response.data.errNo > 0) {
        if (response.data.msg == "未登录") {
          removeToken();
          location.reload();
        }
        ElMessage({
          message: response.data.msg,
          type: "error",
          duration: 5 * 1000,
        });
      }
    }
    return response;
  },
  (error) => {
    console.log("err" + error); // for debug
    ElMessage({
      message: error.message,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;

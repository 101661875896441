<template>
  <div class="header-big">
    <div class="left_header">
      <img class="logo" src="@/assets/logo.png" alt="" />
      <span
        style="
          display: inline-block;
          width: 1px;
          height: 37px;
          background-color: #efefef;
          position: absolute;
          top: 18px;
          left: 206px;
        "
      ></span>
    </div>
    <div class="right_header">
      <div class="header-message">
        <el-icon style="margin-right: 5px"><OfficeBuilding /></el-icon>
        <span>{{ name }}</span>
      </div>
      <div class="header-message" style="padding-right: 0" @click="logout()">
        <i class="iconfont icon-tuichu"></i>
        退出
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "headers",
  data() {
    return {
      name: this.$store.getters["user/user"].name,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("user/LogOut").then(() => {
        location.reload();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.company-span .el-select .el-input__inner {
  border: 0 !important;
  padding-right: 30px;
}
.company-span .el-input__inner {
  padding: 0 6px !important;
}
.company-span .el-input__inner {
  position: relative;
  top: -3px;
}
.company-span .el-input__suffix {
  top: -2px;
}
.header-big {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .el-dropdown-selfdefine {
    color: $color-header-color;
  }
}
.user-header {
  width: 120px;
  .user-menu {
    color: $color-header-color !important;
    display: inline-block;
    width: 100%;
    height: 35px;
    font-size: 14px;
    font-weight: 300;
    line-height: 30px;
    border-bottom: 1px solid #2c3445;
    cursor: pointer;
    &:last-child {
      border-bottom: none;
    }
  }
  border: none;
}
</style>
<style lang="scss" scoped>
.iconfont1 {
  color: white;
}
.header-big {
  position: relative;
  width: 100%;
  height: 70px;
  padding: 0 15px 0 0px;
  border-bottom: 3px solid #eee;
  background: #fff;
  box-shadow: 0 2px 10px #ddd;
  .logo {
    margin-top: 0px;
    float: left;
    width: 189px;
    height: 62px;
    font-size: 14px;
    margin-left: 7px;
  }
  .left_header {
    float: left;
  }
  .right_header {
    display: flex;
    align-items: center;
    span {
      font-size: 16px;
      font-weight: 400;
    }
  }
  .header-message {
    font-size: 15px;
    cursor: pointer;
    padding-right: 30px;
    display: flex;
    align-items: center;
    i {
      font-size: 16px;
      margin-right: 5px;
    }
  }
}
</style>

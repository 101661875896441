import asyncRouterMap from "@/router/routerDt";
import { checkStrInArray } from "@/utils/fun";
export const router = {
  namespaced: true,
  state: {
    asyncRouters: [],
    routerList: [],
  },
  mutations: {
    //列表循环菜单路由
    setRouterList(state, routerList) {
      state.routerList = routerList;
    },
    // 设置动态路由
    setAsyncRouter(state, asyncRouters) {
      state.asyncRouters = asyncRouters;
    },
  },
  actions: {
    async SetAsyncRouter({ commit }, e) {
      commit("setRouterList", asyncRouterMap);
      let rs = asyncRouterMap;
      let arr = [];
      function f(asyncRouterMaps, arrs) {
        asyncRouterMaps.map((v) => {
          if (checkStrInArray(e, v.name) || v.meta.show) {
            let a = Object.assign({}, v);
            a.children = [];
            // console.log('aaa',arrs)
            arrs.push(a);
          }
          //下级
          if (v.children) {
          
           // f(v.children, arrs[arrs.length - 1].children);
          }
        });
      }

      if (e != 1) {
        f(asyncRouterMap, arr);
        rs = arr;
      }
      commit("setAsyncRouter", rs);
    },
  },
  getters: {
    // 获取动态路由
    asyncRouters(state) {
      return state.asyncRouters;
    },
    routerList(state) {
      return state.routerList;
    },
  },
};

import request from '@/utils/request'

//采集,申报,检查等
export function sendTask(data) {
  return request({
    url: 'api/sendTask',
    method: 'post',
    data
  })
}

//任务状态
export function getTaskState(data) {
  return request({
    url: 'api/getTaskState',
    method: 'post',
    data
  })
}
import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
const routes = [
  {
    path: "/login",
    name: "login",
    meta: { noCache: true },
    component: () => import("@/views/login/login.vue"),
  },
  {
    path: "/404",
    name: "err404",
    component: () => import("@/views/redirect/404.vue"),
    meta: { noCache: true },
    hidden: true,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;

import request from "@/utils/request";

export function loginByUsername(username, password, token) {
  const data = {
    username,
    password,
    token,
  };
  return request({
    url: "/api/login",
    method: "post",
    data,
  });
}

export function logout() {
  return request({
    url: "/api/v2/logout",
    method: "get",
  });
}

export function getUserInfo(data) {
  return request({
    url: "/api/userInfo",
    method: "post",
    data,
  });
}

export function sendSms(data) {
  return request({
    url: "/api/sendsms",
    method: "post",
    data,
  });
}

export function register(data) {
  return request({
    url: "/api/register",
    method: "post",
    data,
  });
}

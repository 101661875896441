<template>
    <div class="common_btn">
      <div v-if="status">
        <!-- 成功 或者 默认 -->
        <!-- taskStatus的 -->
        <el-popconfirm
          v-if="state == 0"
          confirm-button-text="确认"
          cancel-button-text="取消"
          icon="InfoFilled"
          icon-color="red"
          title="是否确认发起"
          @confirm="success()"
      >
          <template #reference>
            <el-button  size="small" type="success" plain >{{name}}</el-button>
          </template>
        </el-popconfirm>
        <!-- 排队中 -->
        <el-button v-else-if="state == 1" size="small" type="primary" plain  @click="getInfo()">{{name}}-排队中
          <el-icon class="is-loading">
            <loading />
          </el-icon>
        </el-button>
        <!-- 采集中 -->
        <el-button v-else-if="state == 2" size="small" type="primary" plain  @click="getInfo()">{{name}}-中
          <el-icon class="is-loading">
            <loading />
          </el-icon>
        </el-button>
        <!-- 失败 -->
        <el-popconfirm
          v-else-if="state == 4"
          confirm-button-text="确认"
          cancel-button-text="取消"
          icon="InfoFilled"
          icon-color="red"
          title="是否确认发起"
          @confirm="success()"
      >
          <template #reference>
            <div>
              <el-tooltip  class="item" effect="dark" :content="errorMsg" placement="top">
                <el-button size="small" type="danger" plain >{{name}}-失败</el-button>
              </el-tooltip>
            </div>
          </template>
        </el-popconfirm>
        
  
        <!-- business的 -->
        <!-- 成功 -->
        <el-popconfirm
          v-else-if="state2 == 2"
          confirm-button-text="确认"
          cancel-button-text="取消"
          icon="InfoFilled"
          icon-color="red"
          title="是否确认发起"
          @confirm="success()"
      >
          <template #reference>
            <el-button size="small" type="success" plain >{{name}}-成功</el-button>
          </template>
        </el-popconfirm>
  
        <!-- 失败 -->
        <el-popconfirm
          v-else-if="state2 == 3"
          confirm-button-text="确认"
          cancel-button-text="取消"
          icon="InfoFilled"
          icon-color="red"
          title="是否确认发起"
          @confirm="success()"
      >
          <template #reference>
            <div>
              <el-tooltip class="item" effect="dark" :content="errorMsg" placement="top">
                <el-button size="small" type="danger" plain >{{name}}-失败</el-button>
              </el-tooltip>
            </div>
          </template>
        </el-popconfirm>
        
        <!-- 失败 -->
        <el-popconfirm
          v-else
          confirm-button-text="确认"
          cancel-button-text="取消"
          icon="InfoFilled"
          icon-color="red"
          title="是否确认发起"
          @confirm="success()"
      >
          <template #reference>
            <div>
              <el-tooltip class="item" effect="dark" :content="errorMsg" placement="top">
                <el-button size="small" type="danger" plain>{{name}}-失败</el-button>
              </el-tooltip>
            </div>
          </template>
        </el-popconfirm>
        
      </div>
      
    </div>
  </template>
  
  <script>
  import { getTaskState } from "@/api/collection";
  export default {
    props: {
      name: {
        type: String,
      },
      period: {
        type: String,
      },
      comId:{
        type:Number
      },
      taskName:{
        type:String
      }
    },
    activated(){
      this.pageStatus = true
    },
    deactivated(){
      this.pageStatus = false
    },
    data() {
      return {
        status: false,
        state: 0,
        state2:0,
        errorMsg: "",
        param: {},
        info: {},
        loading: false,
        pageStatus: true,
      };
    },
    created() {
      this.init()
    },
    
    methods: {
      async init(){
        await this.getInfo();
        await this.initBus()  
      },
      async initBus(){
        this.$bus.on("refreshTask", () => {
          if(!this.pageStatus){
            return
          }
          //刷新业务
          let status = false
          if(this.info.taskStatus == 1 || this.info.taskStatus == 2) {
            status = true
          }
          if(status == true) {
            this.getInfo()
          }
        })
      },
      // 按钮
      async success() {
        this.$emit("success")
        setTimeout(() => {
          this.getInfo()
        }, 1000);
      },
      async getInfo() {
        this.loading = true;
        this.refreshStatus();
      },
      // 更新状态采集
      async refreshStatus() {
        let params;
        params = {
          comId: this.comId,
          taskName: this.taskName,
          period: this.period,
        };
        await getTaskState(params).then((res) => {
          this.loading = false;
          this.state = res.data.data.taskStatus;
          this.state2 = res.data.data.bussinessStatus;
          this.errorMsg = res.data.data.bussinessLog?res.data.data.bussinessLog:''+res.data.data.errLog?res.data.data.errLog:'';
          this.status = true;
          this.info = res.data.data;
          if(this.info.taskStatus == 1 || this.info.taskStatus == 2){
            this.loading = true
            // this.initBus()
          }else{
            // 成功之后的回调
            this.$emit('fresh')
          }
          // this.initBus()
        });
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .common_btn {
    display: inline-block;
  }
  </style>
  
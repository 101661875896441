import request from "@/utils/request";
//涉税
//新增涉税机构
export function saveOrg(data) {
  return request({
    url: "api/saveOrg",
    method: "post",
    data,
  });
}
//查询涉税机构
export function getOrgList(data) {
  return request({
    url: "api/orgList",
    method: "post",
    data,
  });
}
//代理人员新增修改
export function savePeople(data) {
  return request({
    url: "api/savePeople",
    method: "post",
    data,
  });
}
//人员列表
export function getPeopleList(data) {
  return request({
    url: "api/peopleList",
    method: "post",
    data,
  });
}
//涉税企业新增修改
export function saveCompany(data) {
  return request({
    url: "api/saveCompany",
    method: "post",
    data,
  });
}
//涉税企业列表
export function getCompanyList(data) {
  return request({
    url: "api/companyList",
    method: "post",
    data,
  });
}
//新增服务协议
export function saveXy(data) {
  return request({
    url: "api/saveXy",
    method: "post",
    data,
  });
}
//服务协议列表
export function getFwxyList(data) {
  return request({
    url: "api/fwxyList",
    method: "post",
    data,
  });
}
//人员删除
export function delPeople(data) {
  return request({
    url: "api/delPeople",
    method: "post",
    data,
  });
}
//企业删除
export function delCompany(data) {
  return request({
    url: "api/delCompany",
    method: "post",
    data,
  });
}
//协议删除
export function delXy(data) {
  return request({
    url: "api/delXy",
    method: "post",
    data,
  });
}

//获取区域
export function getAddress(data) {
  return request({
    url: "api/getAddress",
    method: "post",
    data,
  });
}
